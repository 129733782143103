<template>
  <div v-if="form.order">
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col md="6">
            <h1 class="font-weight-bold header-main text-uppercase">
              รายละเอียดคำสั่งซื้อ หมายเลข :
              <span v-if="form.order.invoiceNo != ''">
                {{ form.order.invoiceNo }}
              </span>
              <span v-else>{{ form.order.quotationNo }}</span>
            </h1>
          </b-col>
          <b-col md="6" class="d-flex justify-content-end align-items-center">
            <button
              type="button"
              @click="returnOrder(form.order.orderStatusId)"
              v-if="
                form.order.orderStatusId == 14 ||
                form.order.orderStatusId == 4 ||
                form.order.orderStatusId == 10 ||
                form.order.orderStatusId == 11
              "
              class="btn btn-main text-uppercase text-nowrap mb-2"
            >
              ยกเลิกและคืนเงินลูกค้า
            </button>
          </b-col>
        </b-row>
        <b-row class="no-gutters mt-2">
          <!-- <b-col class="px-4 px-sm-5 py-4 vh-100" v-if="isLoadingData">
            <img src="/img/loading.svg" class="loading" alt="loading" />
          </b-col>-->

          <b-col>
            <b-row class="bg-white p-3 no-gutters">
              <b-col cols="12">
                <b-row
                  class="justify-content-center justify-content-lg-between align-items-center"
                >
                  <b-col
                    lg="6"
                    md="6"
                    cols="12"
                    class="order-1 order-lg-0 order-md-0"
                  >
                    <p class="font-weight-bold text-center text-lg-left my-2">
                      วันที่ / เวลา :
                      <span class="font-weight-normal">
                        {{
                          $moment(form.order.createdTime).format(
                            $formatDateTimeFull
                          )
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    cols="12"
                    class="text-center text-md-right order-md-1 order-0 order-lg-1"
                  >
                    <p class="font-weight-bold my-2">
                      สถานะ :
                      <b-form-select
                        v-model="form.order.orderStatusId"
                        :class="['mb-1 statusSelect']"
                        ref="test"
                        :disabled="status"
                      >
                        <b-form-select-option
                          :value="item.id"
                          v-for="item in statusList"
                          v-bind:key="item.id"
                          :class="'status-' + item.id"
                          >{{ item.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </p>
                    <div
                      class="f-14 d-inline-block"
                      v-if="form.order.isResendOrder"
                    >
                      จัดส่งใหม่แล้ว
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="6" class="text-lg-left order-0 order-lg-0">
                <b-row>
                  <b-col cols="6">
                    <p class="mb-2 mt-2 font-weight-bold">ข้อมูลผู้ซื้อ</p>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-3">
                  <b-col md="3" class="font-weight-bold">ชื่อ :</b-col>
                  <b-col class="mb-2 mb-sm-0 d-flex" md="9">
                    <template v-if="form.order.roleId == 4"
                      >{{ form.order.firstname }}
                      {{ form.order.lastname }}</template
                    >
                    <template v-else-if="form.order.roleId == 1">{{
                      form.order.companyName
                    }}</template>
                    <div v-if="form.order.salesChannelId == 0">
                      <router-link
                        v-if="form.order.roleId == 4"
                        :to="'/member/details/' + form.order.userGUID"
                      >
                        <span class="f-14 text-blue ml-3 text-underline"
                          >ดูรายละเอียด</span
                        ></router-link
                      >
                      <router-link
                        v-else
                        :to="'/affiliate/details/' + form.order.userGUID"
                      >
                        <span class="f-14 text-blue ml-3 text-underline"
                          >ดูรายละเอียด</span
                        ></router-link
                      >
                    </div>
                    <div v-else><span class="ml-3">(Dropship)</span></div>
                  </b-col>

                  <b-col md="3" class="font-weight-bold">เบอร์โทร :</b-col>
                  <b-col class="mb-2 mb-sm-0" md="9">
                    {{ form.order.telephone || "-" }}
                  </b-col>

                  <b-col md="3" class="font-weight-bold">อีเมล :</b-col>
                  <b-col class="mb-2 mb-sm-0" md="9">
                    {{ form.order.email || "-" }}
                  </b-col>

                  <b-col md="3" class="font-weight-bold">ระดับ :</b-col>
                  <b-col md="9">{{ form.order.memberType || "-" }}</b-col>
                </b-row>
              </b-col>
              <b-col lg="6" class="order-1 order-lg-1">
                <b-row class="mt-lg-5 mb-3 order-2">
                  <b-col md="3" lg="3" class="font-weight-bold pr-0"
                    >ช่องทางการชำระ :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0" md="9" lg="9">{{
                    form.order.paymentChanelName
                  }}</b-col>
                  <!-- <b-col md="3" lg="3" class="font-weight-bold pr-0"
                    >ช่องทางการสั่งซื้อ :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0" md="9" lg="9">{{
                    shoppingChannel
                  }}</b-col> -->
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-2 mx-0">
              <b-col md="6" class="pl-md-0 pr-md-1 px-0 mb-2 mb-md-0">
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="8">
                      <p class="font-weight-bold">ที่อยู่ในการจัดส่ง</p>
                    </b-col>
                    <b-col cols="4" class="text-right">
                      <!-- <p
                        class="pointer text-underline text-blue"
                        @click="printShippingLabel"
                      >
                        พิมพ์
                      </p> -->
                    </b-col>
                  </b-row>

                  <div class="address-box">
                    <p>
                      {{ form.order.shippingAddress.firstname }}
                      {{ form.order.shippingAddress.lastname }}
                    </p>
                    <p>
                      {{ form.order.shippingAddress.address }}
                      {{ form.order.shippingAddress.building }}
                      {{ form.order.shippingAddress.alley }}
                      {{ form.order.shippingAddress.road }}
                      {{ form.order.shippingAddress.subDistrict }}
                      {{ form.order.shippingAddress.district }}
                      {{ form.order.shippingAddress.province }}
                      {{ form.order.shippingAddress.zipCode }}
                    </p>
                    <p class="mt-3">
                      โทร : {{ form.order.shippingAddress.telephone }}
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col md="6" class="pr-md-0 pl-md-1 px-0">
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="8">
                      <p class="font-weight-bold">ที่อยู่ในการออกใบเสร็จ</p>
                    </b-col>
                    <b-col cols="4" class="text-right">
                      <!-- <p
                        class="pointer text-underline text-blue"
                        @click="printShippingLabel"
                      >
                        พิมพ์
                      </p> -->
                    </b-col>
                  </b-row>
                  <template v-if="form.order.includeTaxInvoice">
                    <div class="address-box">
                      <p>
                        {{ form.order.billingAddress.firstname }}
                        {{ form.order.billingAddress.lastname }}
                      </p>
                      <p>
                        {{ form.order.billingAddress.address }}
                        {{ form.order.billingAddress.building }}
                        {{ form.order.billingAddress.alley }}
                        {{ form.order.billingAddress.road }}
                        {{ form.order.billingAddress.subDistrict }}
                        {{ form.order.billingAddress.district }}
                        {{ form.order.billingAddress.province }}
                        {{ form.order.billingAddress.zipCode }}
                      </p>
                      <div class="mt-3">
                        <div
                          v-if="form.order.billingAddress.isNormalPerson === 0"
                        >
                          <p>
                            ชื่อบริษัท :
                            {{ form.order.billingAddress.businessName || "-" }}
                          </p>
                          <p>
                            เลขที่สาขา :
                            {{ form.order.billingAddress.branchNo || "-" }}
                          </p>
                          <p>
                            ชื่อสาขา :
                            {{ form.order.billingAddress.branchName || "-" }}
                          </p>
                        </div>
                        <p>
                          เลขประจำตัวผู้เสียภาษี :
                          {{ form.order.billingAddress.taxInvoice || "-" }}
                        </p>
                        <p>
                          อีเมลในการจัดส่งใบกำกับภาษีอิเล็กทรอนิกส์ :
                          {{ form.order.billingAddress.taxInvoiceEmail || "-" }}
                        </p>
                      </div>
                      <p>โทร : {{ form.order.billingAddress.telephone }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-danger">ไม่ขอรับใบกำกับภาษี</div>
                  </template>
                </div>
              </b-col>
            </b-row>
            <!-- <b-row class="bg-white mt-2 py-1 px-3 no-gutters">
              <b-col cols="12" class="text-right"
                ><u class="cursor-pointer" v-b-modal.modal-cash-back
                  >ดูรายละเอียด</u
                ></b-col
              >
              <b-col lg="6" md="6" class="text-lg-left order-1 order-lg-0">
                <b-row class="mt-2 mb-3">
                  <b-col md="3" lg="4" class="font-weight-bold pr-0"
                    >ผู้ได้รับเงินคืน :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0" md="9" lg="8">
                    {{ form.modelBenefit.userCashBack || "-" }}
                  </b-col>
                  <b-col md="3" lg="4" class="font-weight-bold pr-0"
                    >เปอร์เซ็นต์ :</b-col
                  >
                  <b-col
                    class="mb-2 mb-sm-0"
                    md="9"
                    lg="8"
                    v-if="form.modelBenefit.percentCashBack"
                  >
                    {{ form.modelBenefit.userCashBackTier }} -
                    {{ form.modelBenefit.percentCashBack }}%
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="6" md="6" class="text-lg-left order-1 order-lg-0">
                <b-row class="mt-2 mb-3">
                  <b-col md="3" lg="4" class="font-weight-bold pr-0"
                    >ผู้ได้รับคะแนน :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0" md="9" lg="8">
                    <span>{{ form.modelBenefit.userPoint || "-" }} </span>
                  </b-col>
                  <b-col md="3" lg="4" class="font-weight-bold pr-0"
                    >เปอร์เซ็นต์ :</b-col
                  >
                  <b-col
                    class="mb-2 mb-sm-0"
                    md="9"
                    lg="8"
                    v-if="form.modelBenefit.percentPoint"
                  >
                    {{ form.modelBenefit.userPointTier }} -
                    {{ form.modelBenefit.percentPoint }}%
                  </b-col>
                </b-row>
              </b-col>
            </b-row> -->

            <div class="mt-2">
              <div class="collapse-btn d-flex justify-content-between p-2">
                <div>ข้อมูลการจัดส่ง</div>
                <!-- <div class="d-flex detail-shipping">
                  <div
                    @click.prevent="openModalShipping()"
                    class="cursor-pointer"
                  >
                    อัพเดตข้อมูลจัดส่ง
                  </div>
                  <div
                    class="cursor-pointer"
                    @click.prevent="openModalTracking()"
                  >
                    ดูรายละเอียดการขนส่ง
                  </div>
                </div> -->
              </div>
              <b-collapse id="collapse-tracking" visible>
                <TrackingTimeline
                  :trackingNo="form.order.trackingNoShippingJung"
                  :shippingTypeName="form.order.shippingTypeName"
                  :ownFleetShippingName="form.order.ownFleetShippingName"
                  :dataList="form"
                />
              </b-collapse>
            </div>

            <div class="w-100 mt-3 bg-white">
              <b-table
                striped
                responsive
                :items="itemsOrder"
                :fields="fieldsOrder"
                show-empty
                empty-text="ไม่พบข้อมูล"
                class="table-list m-0"
              >
                <template v-slot:cell(id)="data">
                  <p class="m-0">{{ data.index + 1 }}</p>
                </template>
                <template v-slot:cell(productImageUrl)="data">
                  <div
                    class="square-box b-contain"
                    v-bind:style="{
                      'background-image':
                        'url(' + data.item.productImageUrl + ')',
                    }"
                  ></div>
                </template>
                <template v-slot:cell(productName)="data">
                  <p class="m-0 nobreak">{{ data.item.productName }}</p>
                  <!-- <p class="m-0 nobreak">{{data.item.productShortDescription}}</p> -->
                  <div class="d-flex">
                    <div
                      v-for="(item, index) in data.item.attribute"
                      :key="index"
                      class="config-tag mr-1 mt-1"
                    >
                      {{ item.label }} : {{ item.option.label }}
                    </div>
                  </div>
                  <b-badge
                    pill
                    variant="danger"
                    class="f-14"
                    v-if="data.item.isFreeProduct"
                    >ของแถม</b-badge
                  >
                </template>
                <template v-slot:cell(gp)="data">
                  <p class="m-0">{{ data.item.gp }}%</p>
                </template>
                <template v-slot:cell(subtotal)="data">
                  <p class="m-0">
                    {{ data.item.subtotal | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(discount)="data">
                  <p class="m-0">
                    {{ data.item.discount | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(grandTotal)="data">
                  <p class="m-0">
                    {{ data.item.grandTotal | numeral("0,0.00") }}
                  </p>
                </template>
              </b-table>

              <b-row class="mb-3 p-3">
                <b-col sm="6" offset-md="6">
                  <div class="bg-yellow p-3">
                    <b-row class="pb-2 font-weight-bold">
                      <b-col cols="7">ยอดรวม</b-col>
                      <b-col cols="5" class="text-right"
                        >฿
                        {{
                          form.orderResultDisplay.totalSales | numeral("0,0.00")
                        }}</b-col
                      >
                    </b-row>
                    <b-row class="pb-2 font-weight-bold">
                      <b-col cols="7">ส่วนลด</b-col>
                      <b-col cols="5" class="text-right"
                        >฿ {{ form.order.discount | numeral("0,0.00") }}</b-col
                      >
                    </b-row>
                    <b-row class="pb-2 font-weight-bold">
                      <b-col cols="7">ยอดรวมหลังหักส่วนลด</b-col>
                      <b-col cols="5" class="text-right"
                        >฿
                        {{
                          form.orderResultDisplay.totalSalesAfterDiscount
                            | numeral("0,0.00")
                        }}</b-col
                      >
                    </b-row>
                    <b-row class="pb-2 font-weight-bold">
                      <b-col cols="7">ค่าขนส่ง</b-col>
                      <b-col cols="5" class="text-right"
                        >฿
                        {{
                          form.orderResultDisplay.shippingFee
                            | numeral("0,0.00")
                        }}</b-col
                      >
                    </b-row>
                    <b-row class="pb-2 font-weight-bold">
                      <b-col cols="7">ยอดสุทธิ</b-col>
                      <b-col cols="5" class="text-right"
                        >฿
                        {{ form.order.grandTotal | numeral("0,0.00") }}</b-col
                      >
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="bg-white">
              <b-table
                striped
                responsive
                hover
                :items="items"
                :fields="fields"
                show-empty
                empty-text="ไม่พบข้อมูล"
                class="table-list"
              >
                <template v-slot:cell(createdTime)="data">
                  <span>
                    {{
                      $moment(data.item.createdTime).format($formatDateTimeFull)
                    }}
                  </span>
                </template>
              </b-table>

              <b-row class="mt-5 mx-2 pb-4">
                <b-col md="6">
                  <b-button
                    href="/order"
                    :disabled="isDisable"
                    class="btn-details-set btn-cancel"
                    >ย้อนกลับ</b-button
                  >
                </b-col>
                <b-col md="6" class="text-sm-right">
                  <b-button
                    class="btn-details-set btn-cancel"
                    @click="handleSyncOrder()"
                    :disabled="isDisableSync"
                    >Sync Order</b-button
                  >
                  <button
                    type="button"
                    @click="onChangeStatus(form.order.orderStatusId)"
                    :disabled="isDisable"
                    class="btn btn-main btn-details-set ml-md-2 text-uppercase"
                  >
                    บันทึก
                  </button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <b-modal id="modal-cash-back" hide-footer hide-header>
      <div class="my-4">
        <b>
          มูลค่ารวม GP :
          <span v-if="form.modelBenefit.gpTotal"
            >฿ {{ form.modelBenefit.gpTotal || "-" }}
          </span>
          <span v-else>-</span>
        </b>
      </div>
      <table id="cash-back-detail">
        <thead>
          <tr>
            <th>ประเภท</th>
            <th>ผู้ได้รับ</th>
            <th>% ที่ได้รับ</th>
            <th>รวมมูลค่า</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cashback</td>
            <td>{{ form.modelBenefit.userReceiveCashBack || "-" }}</td>
            <td>
              {{
                form.modelBenefit.percentCashBack
                  ? `${form.modelBenefit.percentCashBack}%`
                  : "-"
              }}
            </td>
            <td>
              {{
                form.modelBenefit.cashback
                  ? `฿ ${form.modelBenefit.cashback}`
                  : "-"
              }}
            </td>
            <td>{{ form.modelBenefit.userCashBack }}</td>
          </tr>
          <tr>
            <td>Point</td>
            <td>{{ form.modelBenefit.userReceivePoint || "-" }}</td>
            <td>
              {{
                form.modelBenefit.percentPoint
                  ? `${form.modelBenefit.percentPoint}%`
                  : "-"
              }}
            </td>
            <td>
              {{
                form.modelBenefit.point
                  ? "฿ " + form.modelBenefit.point.toFixed(2)
                  : "-"
              }}
            </td>
            <td>{{ form.modelBenefit.userPoint }}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-center">
        <b-button
          variant="outline-secondary"
          @click="$bvModal.hide('modal-cash-back')"
          >ย้อนกลับ</b-button
        >
      </div>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-edit-shipping"
      hide-footer
      hide-header
      centered
    >
      <div class="f-18 mb-3"><b>อัพเดตข้อมูลจัดส่ง</b></div>
      <b-row class="align-items-center" style="row-gap: 0.5rem">
        <b-col lg="3" md="4" sm="12">ขนส่งที่ให้บริการ : </b-col>
        <b-col lg="9" md="8" sm="12">
          <b-row>
            <b-col cols="6"
              ><InputSelect
                class="mb-0"
                name="academyId"
                valueField="id"
                textField="name"
                isRequired
              />
            </b-col>
            <b-col cols="6">
              <InputText
                placeholder="ระบุชื่อผู้ให้บริการ"
                type="text"
                name="name"
                class="mb-0"
                isRequired
                textFloat=""
            /></b-col>
          </b-row>
        </b-col>

        <b-col lg="3" md="4" sm="12">หมายเลขติดตามพัสดุ : </b-col>
        <b-col lg="9" md="8" sm="12">
          <InputText
            placeholder="ระบุชื่อผู้ให้บริการ"
            type="text"
            name="name"
            class="mb-0"
            isRequired
            textFloat=""
          />
          <small class="text-danger">
            หมายเหตุ: สำหรับการจัดส่งโดยแบรนด์ จะไม่มีระบบติดตามพัสดุ
            สถานะของคำสั่งซื้อจะเป็นการจัดส่งสำเร็จเมื่อลูกค้ากดยืนยันการรับสินค้า</small
          >
        </b-col>
      </b-row>
      <div class="d-flex">
        <b-button
          variant="outline-secondary"
          class="mr-auto"
          @click="$bvModal.hide('modal-edit-shipping')"
          >ย้อนกลับ</b-button
        >
        <b-button
          variant="outline-secondary"
          class="ml-auto"
          @click="$bvModal.hide('modal-edit-shipping')"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
    <b-modal
      size="lg"
      id="modal-tracking-shipping"
      hide-footer
      hide-header
      centered
    >
      <div class="f-18 mb-3"><b>รายละเอียดการขนส่ง</b></div>
      <div class="w-100 mt-3 bg-white">
        <b-table
          striped
          responsive
          :items="itemsTracking"
          :fields="fieldsTracking"
          show-empty
          empty-text="ไม่พบข้อมูล"
          class="table-list m-0"
        >
        </b-table>
      </div>
    </b-modal>
    <b-modal size="lg" id="modal-return-order" hide-footer hide-header centered>
      <div class="f-18"><b>รายละเอียดขอคืนสินค้า</b></div>
      <ReturnOrder
        :id="form.order.invoiceNo"
        :paymentType="form.order.paymentTypeId"
        @hide="hide()"
      />
    </b-modal>
    <b-modal size="md" id="modal-zort" hide-footer hide-header centered>
      <div class="modal-header p-2">
        <h4>ยืนยันการ Sync Order ?</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('modal-zort')"
        >
          ×
        </button>
      </div>
      <div class="text-center px-md-4 py-2 mt-4">
        <p class="text-text font-weight-bold">คุณแน่ใจที่จะดำเนินการต่อ</p>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" @click="$bvModal.hide('modal-zort')"
          >ย้อนกลับ</b-button
        >
        <b-button
          class="ml-2 btn-modal"
          :variant="'primary'"
          :disabled="isDisableSync"
          @click="updateSyncOrder()"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import * as moment from "moment/moment";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import HeaderLine from "@/components/HeaderLine";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import TrackingTimeline from "@/views/pages/order/component/TrackingTimeline";
import ReturnOrder from "@/views/pages/order/Return";

export default {
  name: "OrderDetails",
  components: {
    HeaderLine,
    InputText,
    InputSelect,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    TrackingTimeline,
    ReturnOrder,
  },
  data() {
    return {
      flag: 0,
      items: [],
      itemsOrder: [],
      itemsTracking: [],
      selected: [],
      statusList: [],
      form: {},
      modalMessage: "",
      isDisable: false,
      isDisableSync: false,
      isLoadingData: false,
      status: false,
      note: "",
      id: this.$route.params.id,
      zort: [],
      fields: [
        {
          key: "createdTime",
          label: "วันที่ทำรายการ",
          class: "w-100px",
        },
        {
          key: "orderSatusName",
          label: "สถานะ",
          class: "w-100px",
        },
        {
          key: "updatedByName",
          label: "ทำรายการโดย",
          class: "w-100px",
        },
      ],
      fieldsTracking: [
        {
          key: "createdTime",
          label: "วันที่ทำรายการ",
          class: "w-100px",
        },
        {
          key: "orderSatusName",
          label: "รายละเอียด",
          class: "w-100px",
        },
        {
          key: "updatedByName",
          label: "สถานะ",
          class: "w-100px",
        },
        {
          key: "updatedByName1",
          label: "หมายเหตุ",
          class: "w-100px",
        },
      ],
      fieldsOrder: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "sku",
          label: "SKU",
          class: "w-100px",
        },
        {
          key: "productImageUrl",
          label: "ภาพประกอบ",
          class: "w-100px",
        },
        {
          key: "productName",
          label: `รายละเอียด`,
          tdClass: "text-left w-200",
          thclass: "w-200",
        },
        // {
        //   key: "gp",
        //   label: `GP`,
        //   class: "w-100px",
        // },
        {
          key: "orderItemQuantity",
          label: `จำนวน`,
        },
        {
          key: "subtotal",
          label: `ราคา`,
          class: "w-100px",
        },
        {
          key: "discount",
          label: `ส่วนลด`,
          class: "w-100px",
        },
        // {
        //   key: "discountSeller",
        //   label: `ส่วนลดนักขายออนไลน์`,
        //   class: "w-100px",
        // },
        {
          key: "grandTotal",
          label: `รวม`,
          class: "w-100px",
        },
      ],
    };
  },
  computed: {
    shoppingChannel() {
      var channel = "";
      // if (this.form.order.codeShare) {
      //   return "ซื้อจาก Link";
      // }
      if (this.form.order.salesChannelId == 0) {
        return "ซื้อผ่านทาง Brand";
      }
      if (
        this.form.order.postId != null &&
        this.form.order.salesChannelId == 1
      ) {
        return "ซื้อผ่านทาง Live";
      }
      if (
        this.form.order.postId == null &&
        this.form.order.salesChannelId == 1
      ) {
        return "ซื้อผ่านทาง Dropship";
      }
    },
  },
  created: async function () {
    await this.getData();
    await this.checkStatus();
  },
  methods: {
    openModalShipping() {
      this.$bvModal.show("modal-edit-shipping");
    },
    openModalTracking() {
      this.$bvModal.show("modal-tracking-shipping");
    },
    returnOrder() {
      this.$bvModal.show("modal-return-order");
    },
    hide() {
      this.$bvModal.hide("modal-return-order");
    },
    moment: function () {
      return moment();
    },
    checkStatus: function (evt) {
      var status = this.form.order.orderStatusId;
      if (status == 9 || status == 6 || status == 7 || status == 5) {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    handleSyncOrder: async function () {
      this.$bvModal.show("modal-zort");
    },
    updateSyncOrder: async function () {
      this.isDisableSync = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/transaction/UpdateOrderThirdParty/1/${this.form.order.id}`,
        null,
        this.$headers,
        null
      );
      this.isDisableSync = false;
      this.$refs.modalLoading.hide();
      this.$bvModal.hide("modal-zort");

      this.modalMessage = data.message;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 3000);
      }
    },
    getData: async function () {
      this.isLoadingData = true;

      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/OrderStatus`,
        null,
        this.$headers,
        null
      );
      if (status.result == 1) {
        this.statusList = status.detail;
        this.statusList.shift();
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;

        this.items = data.detail.orderStatusLogList;
        this.itemsOrder = data.detail.orderItemList;

        this.isLoadingData = false;
        this.$isLoading = true;
      }
    },
    printShippingLabel: async function () {
      this.$refs.modalLoading.show();

      axios({
        url: `http://s.boxme.asia/api/v1/orders/awb-label/YTMwYmY5NDYtYzdhNi00Njk0LTg3YzgtNDQwZmQ4YjAwZDc1`,
        method: "get",
        headers: this.$headers,
        responseType: "blob",
      })
        .then((response) => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          this.modalAlertShow = false;

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Shipping Label-` + dateExcel + `.pdf`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          if (error.response.status === 500) {
            // this.imgModal = "/img/icon-unsuccess.png";
            // this.msgModal =
            //   "Internal Server Error. Please contact system administrator";
            // this.hideClose = false;
          }
        });
    },
    onChangeStatus: async function (value) {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let body = {
        transactionId: this.id,
        note: "",
        statusId: value,
      };

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/transaction/ChangeStatusOrder`,
        null,
        this.$headers,
        body
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;

      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
        this.checkStatus();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.detail-shipping div {
  margin: 0px 5px;
  text-decoration: underline;
}
#cash-back-detail {
  width: 100%;
  thead {
    background-color: #c5c5c5;
    th {
      text-align: center;
    }
  }
  td {
    text-align: center;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.no-wrap {
  white-space: nowrap;
}
.sub-color {
  color: #c9b67e;
}

.pt-100 {
  padding-top: 100%;
}

.slip-details {
  line-height: 25px;
}

.address-box p {
  margin: 0;
}

.address-box {
  line-height: 25px;
}

.input-disabled {
  width: 50px;
}

.h-200 {
  min-height: 200px;
}

.pab-0,
.pab {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.right-0 {
  right: 15px;
}

.f-17 {
  font-size: 17px;
}

.order-desc {
  font-size: 13px;
  color: #6b6b6b;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.order-discount {
  color: #c9b67e;
  font-size: 13px;
}

.bg-yellow {
  background: #fff2d6;
  color: #333333;
}

.delete-order {
  clear: both;
  text-align: right;
}

.image-preview {
  padding-top: 50%;
  width: 50%;
  margin: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.square-box-zort {
  width: 40% !important;
  padding-top: 40% !important;
}

.statusSelect {
  width: 235px;
  height: auto;
  position: relative;
  left: 10px;
  top: 2px;
  -webkit-appearance: none;
}

.config-tag {
  background: #ff0641;
  padding: 1px 5px;
  color: white;
  border-radius: 15px;
  font-size: 12px;
  white-space: nowrap;
}

@media (max-width: 992px) {
  .pab-0 {
    position: unset;
    width: auto;
  }

  .input-disabled {
    float: right;
  }

  .statusSelect {
    text-align-last: center;
  }
}

@media (max-width: 600px) {
  .statusSelect {
    width: 200px;
  }
}
</style>
